import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import parse from "url-parse"
import { PageTitle, PolicyTitle, PolicyPara, HighlightLink, HightlightText } from '../../styles/pages/policyPage.styles'

const Link = ({children}) => {
    const {protocol, host} = parse(children)
    return(
        <HighlightLink><small>{`${protocol}//`}</small>{host}</HighlightLink>
    )
}

const Terms = () => {
    return(
        <Container fluid>
            <Row className="mx-auto my-5">
                <Col sm="12" md="11">
                    <PageTitle>Terms and Conditions</PageTitle>
                    <small>Policy</small>
                </Col>
            </Row>
            <Row className="mx-auto mt-3">
                <Col sm={12} md={8}>
                    <Row className="mb-3">
                        <Col md={12}>
                            <PolicyPara>Rayhan.in is owned and operated by Rayhan. All information, products and services displayed on the <Link>https://rayhan.in/</Link> website constitute an "invitation to offer". Your order for purchase constitutes your "offer" which shall be subject to the terms and conditions as listed below. <Link>https://rayhan.in/</Link> website has the right to accept or reject your offer without assigning any reason thereof.</PolicyPara>
                            <PolicyPara>To use the <Link>https://rayhan.in/</Link> website belonging to Rayhan, you (The User) have accepted the following terms and conditions given below. Reserves the right to add, delete, alter or modify these terms and conditions at any time.</PolicyPara>
                            <PolicyPara>You (The User) are therefore advised to read carefully these terms and conditions every time you use the <Link>https://rayhan.in/</Link> website of Rayhan.</PolicyPara>
                        </Col>
                    </Row>
                    <Row md={2} className="gy-2">
                        <Col>
                            <PolicyTitle>Order Fulfillment</PolicyTitle>
                            <PolicyPara>Rayhan will take <HightlightText>"Full Responsibility"</HightlightText> of your Order, if the Order has been shipped to any city, where we used "Private Courier Companies" (e.g. Xpressbees, Delhivery etc) that services your pin code to ship your order.</PolicyPara>
                        </Col>
                        <Col>
                            <PolicyTitle>Order Cancellations</PolicyTitle>
                            <PolicyPara>Request for cancellations of orders once placed on <Link>https://rayhan.in/</Link> shall be entertained only if your order hasn't already been dispatched.</PolicyPara>
                        </Col>
                        <Col>
                            <PolicyTitle>Incorrect Pricing</PolicyTitle>
                            <PolicyPara>Rayhan reserves the right to refuse or cancel any order placed for a product that is listed at an incorrect price or for any other reason. This shall be regardless of whether the order has been confirmed and/or payment been received. The payment shall be refunded and the User shall be informed of the same.</PolicyPara>
                        </Col>
                        <Col>
                            <PolicyTitle>Delayed Delivery</PolicyTitle>
                            <PolicyPara>If a non-delivery or late delivery occurs due to a mistake by the User (i.e. wrong or incomplete name or address or recipient not available) any extra cost spent by Rayhan for re-delivery shall be claimed from the User placing the order.</PolicyPara>
                        </Col>
                        <Col>
                            <PolicyTitle>Customer Information</PolicyTitle>
                            <PolicyPara>The User agrees to give accurate, authentic and true information. Rayhan reserves the right to confirm and validate the information and other details provided by the User at any point of time. If any such User details are found not to be true wholly or partly, Rayhan has the right in its sole discretion to reject the registration and debar the User from using the services of <Link>https://rayhan.in/</Link> without prior intimation whatsoever.</PolicyPara>
                        </Col>
                        <Col>
                            <PolicyTitle>Third party Fraud</PolicyTitle>
                            <PolicyPara>Rayhan will not be liable for any type or kind of credit card fraud. The liability to use a card fraudulently will be on the user and the onus to 'prove otherwise' shall be exclusively on the user. The User must exclusively use his/her own card on the site.</PolicyPara>
                        </Col>
                        <Col>
                            <PolicyTitle>Exclusive Jurisdiction</PolicyTitle>
                            <PolicyPara>This agreement shall be construed in accordance with the applicable laws of India. The Courts at Tamil Nadu shall have exclusive jurisdiction in any proceedings arising out of this agreement.</PolicyPara>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Terms